import React,{Component} from "react";
import {Box, Card, Container, Divider, List, ListItem, ListItemText, Theme, Typography} from "@mui/material";

export default class About extends Component<{theme:Theme}, {}>{
    render() {
        return (
            <Container style={{paddingRight:0, paddingLeft:0}}>
                <img style={{width:"100%"}} src={this.props.theme.palette.mode === "dark"?"./images/header_dark.png":"./images/header.png"}/>
                <Card elevation={3} sx={{m:"10px"}}>
                    <Typography color={this.props.theme.palette.getContrastText(this.props.theme.palette.primary.main)} textAlign={"center"} sx={{backgroundColor:this.props.theme.palette.primary.main}} paddingBottom={"10px"} paddingTop={"30px"} paddingX={"20px"} fontSize={24} dir={"rtl"}>عن الشركة</Typography>
                    <Divider/>
                    <Typography marginY={"20px"} marginX={"30px"} fontSize={18} dir={"rtl"}>
                        نقدم الدعم الفني على مدار الساعة ونقوم يإصلاح الأعطال الطارئة في أسرع وقت ممكن.
                        <br/><br/>
                        نقوم بتقديم الصيانة الدورية من أجل التأكد من سلامة الأجهزة وجعلها تعمل بالشكل الأمثل.
                        <br/><br/>
                        نقدم المشورة والتوصيات بشأن أنسب الخيارات لتحسين نظم تكنولوجيا المعلومات وبأقل تكلفة ممكنة عن طريق دراسة احتياجات العميل والإشراف على تحقيقها حسب رغبته
                        <br/><br/>
                        وبذلك نسعى للارتقاء بمستوى الخدمات المعلوماتية والتكنولوجية ونقوم بتطويرها بشكل مستمر
                        <br/><br/>
                        <br/><br/>
                        <List>
                            من أهم مزايانا:
                            <ListItem sx={{textAlign:"right"}}><ListItemText>
                                الرد السريع على العملاء
                            </ListItemText></ListItem>
                            <ListItem sx={{textAlign:"right"}}><ListItemText>
                                المواعيد الدقيقة
                            </ListItemText></ListItem>
                            <ListItem sx={{textAlign:"right"}}><ListItemText>
                                الجودة العالية
                            </ListItemText></ListItem>
                            <ListItem sx={{textAlign:"right"}}><ListItemText>
                                سهولة التواصل
                            </ListItemText></ListItem>
                            <ListItem sx={{textAlign:"right"}}><ListItemText>
                                فريق متميز في كافة الاقسام
                            </ListItemText></ListItem>
                        </List>
                    </Typography>
                </Card>
            </Container>
        );
    }
}