import React, {useState} from 'react';
import './App.css';
import {
    AppBar,
    Box, Container,
    Drawer,
    Grid,
    IconButton, Link, List, ListItemButton, ListItemIcon, ListItemText, Paper, Stack,
    SvgIcon,
    SvgIconProps,
    Tab,
    Tabs, Theme, Toolbar,
    Typography
} from "@mui/material";
import {useNavigate, Routes, Route, useLocation} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import Home from "./routes/home";
import About from "./routes/about";
import FourOFour from "./routes/FourOFour";
import {ReactComponent as Logo} from "./logo.svg";
import {Book, Email, Facebook, Info, Instagram, Phone} from "@mui/icons-material";


function LogoIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <Logo/>
        </SvgIcon>
    );
}

function isSmall(): boolean {
    return document.documentElement.clientWidth < 883;
}

function App(props:{theme:Theme}) {
    const location1 = useLocation();
    let i: number;
    const currentPagePath = location1.pathname.split("/");
    const currentPage = currentPagePath.length > 1 ? currentPagePath[1] : "";
    const tabs = ["Home", "Blog", "About"];
    const tabsIcons = [<HomeIcon/>, <Book/>, <Info/>];
    switch (currentPage.toLowerCase()) {
        case "":
        case "home":
            i = tabs.indexOf("Home");
            break;
        case "about":
            i = tabs.indexOf("About");
            break;
        case "blog":
            i = tabs.indexOf("Blog");
            break;
        default:
            i = -1;
    }
    const [tab, setTab] = useState<number>(i);
    const navigate = useNavigate();

    const onResize = () => {
        if (isSmall()) {
            setSmallScreen(true);
            setScreenWidth(document.documentElement.clientWidth);
        }else {
            setSmallScreen(false);
            toggleDrawer(false);
        }
    };
    const [smallScreen, setSmallScreen] = useState<boolean>(isSmall());
    const [screenWidth, setScreenWidth] = useState<number>(document.documentElement.clientWidth);
    const [isDrawerOpen, toggleDrawer] = useState<boolean>(false);

    window.addEventListener("resize", onResize);

    const selectPage = (index:number) => {
        if (index === tabs.indexOf("Blog")) {
            window.location.href = '/blog';
        }
        else {
            setTab(index);
            const to = index === 0 ? "/" : "/" + tabs[index].toLowerCase();
            navigate(to, {replace: true});
        }
    };

    return (
        <Paper square sx={{width: '100%', minHeight:"100%", overflow:"auto"}} className="App">
            <Box>
                <AppBar enableColorOnDark sx={{zIndex:1500}} position="fixed">
                    {smallScreen ? (
                            <Toolbar>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={()=>{toggleDrawer(!isDrawerOpen)}}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Link color={props.theme.palette.getContrastText(props.theme.palette.primary.main)} href={"/"} underline={"none"}>
                                    <Stack direction={"row"} spacing={0}>
                                        <LogoIcon sx={{mr: "12px", py: "12px"}} color={"inherit"}/>
                                        <Typography sx={{py: "12px"}}>Pulpo</Typography>
                                    </Stack>
                                </Link>
                                <Drawer open={isDrawerOpen} onClose={()=>{toggleDrawer(false)}}>
                                    <Box
                                        sx={{ minWidth: "150px",width: (0.85 * screenWidth) + "px" ,background:"inherit"}}
                                        role="presentation"
                                        onClick={()=>{toggleDrawer(false)}}
                                        onKeyDown={()=>{toggleDrawer(false)}}
                                    >
                                        <Toolbar/>
                                        <List>
                                            {tabs.map((text, index) => (
                                                <ListItemButton selected={index === tab} onClick={()=>selectPage(index)} key={text}>
                                                    <ListItemIcon>
                                                        {tabsIcons[index]}
                                                    </ListItemIcon>
                                                    <ListItemText primary={text} />
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Box>
                                </Drawer>
                            </Toolbar>
                        ) : (
                            <Box paddingX={"24px"}>
                                <Grid justifyContent={"space-around"} container>
                                    <Link color={props.theme.palette.getContrastText(props.theme.palette.primary.main)} href={"/"} underline={"none"}>
                                        <Stack direction={"row"} spacing={0}>
                                            <LogoIcon sx={{mr: "12px", py: "12px"}} color={"inherit"}/>
                                            <Typography sx={{py: "12px"}}>Pulpo</Typography>
                                        </Stack>
                                    </Link>
                                    <Grid xs={5} item>
                                        <Box>
                                            <Tabs
                                                variant="fullWidth"
                                                textColor="inherit"
                                                indicatorColor="secondary"
                                                value={tab}>
                                                {tabs.map((tabText, index) => {
                                                    return (<Tab onClick={()=>selectPage(index)} id={`simple-tab-${index}`} key={`simple-tab-${index}`} label={tabText}/>)
                                                })}
                                            </Tabs>

                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>)}
                </AppBar>

                <Box sx={{height: "48px"}}/>

                <Routes>
                    <Route path="/" element={<Home theme={props.theme}/>}/>
                    <Route path="/home" element={<Home theme={props.theme}/>}/>
                    <Route path="/blog" element={<></>}/>
                    <Route path="/about" element={<About theme={props.theme}/>}/>
                    <Route path="*" element={<FourOFour message={"404 Page not found!"}/>}/>
                </Routes>

                <Box marginY={"20px"} padding={"15px"} sx={{backgroundColor: props.theme.palette.primary.main}}>
                    <Container sx={{height:"100%"}}>
                        <Stack spacing={"10px"}>
                            <Stack direction={{xs:"column",md:"row"}} spacing={"7px"} alignItems={"center"} justifyContent={"space-between"} sx={{color:props.theme.palette.getContrastText(props.theme.palette.primary.main)}}>
                                <Link target={"_blank"} underline={"none"} href={"https://www.facebook.com/Pulpo.Tech"} color={props.theme.palette.getContrastText(props.theme.palette.primary.main)} fontSize={{xs:12,sm:14,md:16}}>
                                    <Stack spacing={"5px"} direction={"row"}><Facebook/><Typography>Pulpo.Tech</Typography></Stack>
                                </Link>
                                <Link target={"_blank"} underline={"none"} href={"https://www.instagram.com/pulpo_tech/"} color={props.theme.palette.getContrastText(props.theme.palette.primary.main)} fontSize={{xs:12,sm:14,md:16}}>
                                    <Stack spacing={"5px"} direction={"row"}><Instagram/><Typography>Pulpo_Tech</Typography></Stack>
                                </Link>
                                <Link underline={"none"} href={"mailto:info@pulpo-tech.com"} color={props.theme.palette.getContrastText(props.theme.palette.primary.main)} fontSize={{xs:12,sm:14,md:16}}>
                                    <Stack spacing={"5px"} direction={"row"}><Email/><Typography>info@pulpo-tech.com</Typography></Stack>
                                </Link>
                                <Link alignItems={"center"} underline={"none"} href={"tel:+963998480066"} color={props.theme.palette.getContrastText(props.theme.palette.primary.main)} fontSize={{xs:12,sm:14,md:16}}>
                                    <Stack spacing={"5px"} direction={"row"}><Phone/><Typography>+963 998 480 066</Typography></Stack>
                                </Link>
                            </Stack>
                            <small style={{color:props.theme.palette.getContrastText(props.theme.palette.primary.main),textAlign:"center"}}>&copy; Copyright 2022, Pulpo-Tech</small>
                        </Stack>
                    </Container>
                </Box>
            </Box>
        </Paper>
    );
}

export default App;
