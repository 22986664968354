import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {createTheme, Theme, ThemeProvider} from "@mui/material";
import {amber, deepPurple} from "@mui/material/colors";
import "./fonts/fonts.css"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme:Theme = createTheme({
    palette:{
        mode:window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
        primary:deepPurple,
        secondary:amber
    },
    typography:{
        fontFamily:[
            '"Segoe UI"',
            "'Noto Sans Arabic'",
            'sans-serif',
            'Roboto'
        ].join(',')
    }
});


root.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
          <React.StrictMode>
            <App theme={theme}/>
          </React.StrictMode>
        </BrowserRouter>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
