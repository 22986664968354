import React,{Component} from "react";
import {Box, Paper, Typography} from "@mui/material";

export default class FourOFour extends Component<{message:string}, {height:number}>{
    state = {height:(document.documentElement.clientHeight - 125)};
    constructor(props:{message:string}) {
        super(props);
        window.addEventListener("resize",this.onResize.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener("resize",this.onResize.bind(this))
    }

    onResize(){
        this.setState(state=>({height:document.documentElement.clientHeight - 125}))
    }
    render() {
        return (
            <Paper sx={{p:"10px",textAlign:"center"}}>
                <Typography padding={"10px"} fontSize={24}>{this.props.message}</Typography>
                <Box sx={{
                    height: (document.documentElement.clientHeight - 350) + "px",
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxWidth: "730px"
                }}>
                    <img style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        position: "relative"
                    }} src={"https://www.pulpo-tech.com/404.gif"}/>
                </Box>
            </Paper>
        );
    }
}