import React,{Component} from "react";
import {Card, CardMedia, Divider, Stack, Typography} from "@mui/material";
interface ServiceCardProps{
    defaultDir:boolean;
    text:string;
    imageSrc?:string;
    title?:string;
    fontSize?:any ;
    dir?:"rtl"|"ltr";
    fontSizeTitle?:any ;
    dirTitle?:"rtl"|"ltr";
}
export default class ServiceCard extends Component<ServiceCardProps, any>{
    render() {
        return (
            <Card elevation={3} sx={{width:"calc(100% - 32px)",m:"16px",p:0}}>
                <Stack>
                    {
                        (this.props.title != null) &&
                        (
                            <>
                                <Typography variant={"h3"} align={"center"} marginX={"16px"} marginTop={"16px"} padding={"10px"} fontSize={this.props.fontSizeTitle} dir={this.props.dirTitle}>
                                    {this.props.title}
                                </Typography>
                                <Divider sx={{mb:"10px"}}/>
                            </>
                        )
                    }
                    <Stack dir={this.props.dir} alignItems={"center"}
                           direction={{ xs: 'column', md: this.props.defaultDir?"row":"row-reverse" }}>
                        {(this.props.imageSrc != null) && (<CardMedia component={"img"} sx={{width:{xs:"100%",md:"40%"}, maxWidth:{xs:"100%",md:"40%"}, height:{xs:"auto",md:"auto"}}} image={this.props.imageSrc}/>)}
                        <Typography dangerouslySetInnerHTML={{__html:this.props.text}} margin={"16px"} padding={"10px"} fontSize={this.props.fontSize} dir={this.props.dir}/>
                    </Stack>
                </Stack>
            </Card>
        );
    }
}