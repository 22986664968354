import React,{Component} from "react";
import {Container, Paper, Stack, Theme, Typography} from "@mui/material";
import ServiceCard from "../components/ServiceCard";
import Card from "../context/Card";
import cards from "../data.json"

export default class Home extends Component<{theme:Theme}, {}>{
    cardsList:{topCards:Card[],inlineCards:Card[]};

    constructor(props:{theme:Theme}) {
        super(props);
        this.cardsList = cards;
    }
    render() {
        return (
            <Container style={{paddingRight:0, paddingLeft:0}}>
                <img style={{width:"100%"}} src={this.props.theme.palette.mode === "dark"?"./images/header_dark.png":"./images/header.png"}/>
                <Container sx={{pt:"10px"}}>
                    <Stack>
                        {this.cardsList.topCards.map((card,index)=>{
                            return (
                                <ServiceCard
                                    key={`big-card-${index}`}
                                    fontSize={20}
                                    dir={"rtl"}
                                    fontSizeTitle={25}
                                    dirTitle={"ltr"}
                                    imageSrc={card.imgUrl}
                                    defaultDir={index % 2 === 0}
                                    title={card.title}
                                    text={card.text}/>
                            );
                        })}
                        <Stack justifyContent={"space-evenly"} direction={{ xs: 'column', md: 'row-reverse' }}>
                            {this.cardsList.inlineCards.map((card,index)=>{
                                return (
                                    <ServiceCard
                                        key={`big-card-${index}`}
                                        fontSize={15}
                                        dir={"rtl"}
                                        fontSizeTitle={20}
                                        dirTitle={"ltr"}
                                        imageSrc={card.imgUrl}
                                        defaultDir={true}
                                        title={card.title}
                                        text={card.text}/>
                                );
                            })}
                        </Stack>
                    </Stack>
                </Container>
            </Container>
        );
    }
}